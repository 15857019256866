'use client'
import * as linkify from 'linkifyjs'
import { ArrowIcon, NewsletterInput } from '@/components/components.exports'
import { useId, useRef, useState } from 'react'
import { errorToast, successToast } from '@/lib/toast.lib'
import { addToNewsletter } from '@/services/notification.service'
import { useFormStatus } from 'react-dom'
import { AiOutlineLoading3Quarters } from 'react-icons/ai'

type SubmitButtonProps = {
  externalDisabled?: boolean
}

function SubmitButton({ externalDisabled }: SubmitButtonProps) {
  const { pending } = useFormStatus()
  return (
    <button
      type="submit"
      aria-label="Submit"
      disabled={externalDisabled || pending}
      className="flex aspect-square h-full items-center justify-center rounded-xl bg-[#0F1634] text-white transition hover:bg-neutral-800 disabled:cursor-not-allowed disabled:opacity-50"
    >
      {pending ? (
        <AiOutlineLoading3Quarters className="animate-spin" />
      ) : (
        <ArrowIcon className="w-4" />
      )}
    </button>
  )
}

export function NewsletterForm() {
  const [email, setEmail] = useState('')
  const formRef = useRef<HTMLFormElement>(null)

  async function handleAddToNewsletter(formData: FormData) {
    formRef.current?.reset()
    setEmail('')

    const result = await addToNewsletter(formData)
    if (result?.error) {
      errorToast(result.error)
    } else {
      successToast('Mensagem enviada com sucesso')
    }
  }
  return (
    <form
      ref={formRef}
      action={handleAddToNewsletter}
      style={{ position: 'relative' }}
      className="max-w-sm"
    >
      <h2 className="font-display text-sm font-semibold tracking-wider text-neutral-950">
        Movimentações relevantes para o seu negócio
      </h2>
      <p className="mt-4 text-sm text-neutral-700">
        Deixe seu email para ser informado semanalmente sobre notícias, artigos
        eventos e movimentações jurídicas relevantes para o setor trabalhista
        empresarial.
      </p>
      <div className="relative mt-6">
        <input
          type="text"
          name="token"
          autoComplete="off"
          style={{ opacity: 0.0, position: 'absolute', zIndex: -100 }}
        />
        <NewsletterInput
          label="Email"
          name="email"
          autoComplete="email"
          onChange={(e) => setEmail(e.target.value)}
        />
        <div className="absolute inset-y-1 right-1 z-10 flex justify-end">
          <SubmitButton externalDisabled={!linkify.test(email)} />
        </div>
      </div>
    </form>
  )
}
