export enum BrevoTemplate {
  ContactMeReply = 2,
  WelcomeToNewsletter = 1,
  ContactRequest = 3,
}

export enum BrevoLists {
  Onboarding = 6,
  Newsletter = 5,
}

export type BrevoListNames = keyof typeof BrevoLists
export type BrevoTemplateNames = keyof typeof BrevoTemplate
