import { brevoService, BrevoService } from '@/services/services.exports'
import nodemailer from 'nodemailer'
import { Mailable } from '@/constants/constants.exports'

export class EmailService {
  //@ts-ignore

  private brevo: BrevoService

  constructor() {
    this.brevo = brevoService
  }

  /**
   *
   */
  send = async <T>(mailable: Mailable<T>) => {
    try {
      await this.brevo.sendTransactional({
        to: mailable.to,
        templateId: mailable.templateId,
        params: mailable.params,
      })
    } catch (err) {
      throw err
    }
  }
}
