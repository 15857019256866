import { toast } from 'react-toastify'
import { defaultToastOptions } from '@/configurations/toasts.config'

export function successToast(message: string) {
  return toast.success(message, { ...defaultToastOptions })
}

export function errorToast(message: string) {
  return toast.error(message, { ...defaultToastOptions })
}

export function warningToast(message: string) {
  return toast.warn(message, { ...defaultToastOptions })
}

export function infoToast(message: string) {
  return toast.info(message, { ...defaultToastOptions })
}
