'use client'
import * as linkify from 'linkifyjs'
import { FadeIn, TextInput, Button } from '@/components/components.exports'

import { useRef, useState } from 'react'
import { useFormStatus } from 'react-dom'
import { AiOutlineLoading3Quarters } from 'react-icons/ai'
import { sendContactRequestEmail } from '@/services/notification.service'
import { errorToast, successToast } from '@/lib/toast.lib'

function SubmitButton({ externalDisabled }: { externalDisabled?: boolean }) {
  const { pending } = useFormStatus()
  return (
    <Button
      type="submit"
      aria-label="Submit"
      disabled={externalDisabled || pending}
      className="mt-4"
      invert
    >
      {pending ? (
        <AiOutlineLoading3Quarters className="animate-spin" />
      ) : (
        <span>Enviar</span>
      )}
    </Button>
  )
}
function FormRow({ children }: { children: React.ReactNode }) {
  return <div className="flex">{children}</div>
}

export function ContactFormShort() {
  const [email, setEmail] = useState('')
  const [name, setName] = useState('')
  const [message, setMessage] = useState('')
  const formRef = useRef<HTMLFormElement>(null)

  async function handleClientRequest(formData: FormData) {
    formRef.current?.reset()
    setEmail('')
    setName('')
    setMessage('')

    const result = await sendContactRequestEmail(formData)
    if (result?.error) {
      errorToast(result.error)
    } else {
      successToast('Mensagem enviada com sucesso')
    }
  }

  return (
    <FadeIn className="lg:order-last">
      <form style={{ position: 'relative' }} action={handleClientRequest}>
        <div className="isolate mt-6 -space-y-px  overflow-hidden rounded-2xl bg-white">
          <input
            type="text"
            name="token"
            autoComplete="off"
            style={{ opacity: 0.0, position: 'absolute', zIndex: -100 }}
          />
          <FormRow>
            <TextInput
              label="Nome"
              name="name"
              autoComplete="name"
              max={50}
              maxLength={50}
              onChange={(e) => setName(e.target.value)}
            />
            <TextInput
              label="Email"
              type="email"
              name="email"
              autoComplete="email"
              max={50}
              maxLength={50}
              onChange={(e) => setEmail(e.target.value)}
            />
          </FormRow>

          <TextInput
            label="Mensagem"
            name="message"
            max={500}
            maxLength={500}
            onChange={(e) => setMessage(e.target.value)}
          />
        </div>
        <div className="mt-6 flex">
          <SubmitButton
            externalDisabled={!linkify.test(email) || !name || !message}
          />
        </div>
      </form>
    </FadeIn>
  )
}
