import { useId } from 'react'

export function NewsletterInput({
  label,
  ...props
}: React.ComponentPropsWithoutRef<'input'> & { label: string }) {
  let id = useId()

  return (
    <div className="group relative z-0 w-full  transition-all  focus-within:z-10">
      <input
        id={id}
        type="email"
        placeholder="Digite seu endereço de email"
        autoComplete="email"
        aria-label="Email address"
        {...props}
        className="block w-full rounded-2xl border border-neutral-300 bg-transparent py-4 pl-6 pr-20 text-base/6 text-neutral-950 ring-4 ring-transparent transition placeholder:text-neutral-500 focus:border-neutral-950 focus:outline-none focus:ring-neutral-950/5 "
      />
    </div>
  )
}
