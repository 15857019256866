'use client'

import * as linkify from 'linkifyjs'
import { Button, FadeIn, TextInput } from '@/components/components.exports'
import { errorToast, successToast } from '@/lib/toast.lib'

import { sendContactRequestEmail } from '@/services/services.exports'
import { useRef, useState } from 'react'

import { useFormStatus } from 'react-dom'
import { AiOutlineLoading3Quarters } from 'react-icons/ai'

function SubmitButton({ externalDisabled }: { externalDisabled?: boolean }) {
  const { pending } = useFormStatus()
  return (
    <Button
      type="submit"
      aria-label="Submit"
      disabled={externalDisabled || pending}
      className="mt-10"
    >
      {pending ? (
        <AiOutlineLoading3Quarters className="animate-spin" />
      ) : (
        <span>Enviar</span>
      )}
    </Button>
  )
}

export function ContactFormLong() {
  const [email, setEmail] = useState('')
  const [name, setName] = useState('')
  const [message, setMessage] = useState('')
  const formRef = useRef<HTMLFormElement>(null)

  async function handleClientRequest(formData: FormData) {
    formRef.current?.reset()
    setEmail('')
    setName('')
    setMessage('')
    const result = await sendContactRequestEmail(formData)
    if (result?.error) {
      errorToast(result.error)
    } else {
      successToast('Mensagem enviada com sucesso')
    }
  }

  return (
    <FadeIn className="lg:order-last">
      <form ref={formRef} action={handleClientRequest}>
        <div
          style={{ position: 'relative' }}
          className="isolate mt-6 -space-y-px rounded-2xl bg-white/50 "
        >
          <input
            type="text"
            name="token"
            autoComplete="off"
            style={{ opacity: 0.0, position: 'absolute', zIndex: -100 }}
          />
          <TextInput
            label="Name"
            name="name"
            autoComplete="name"
            max={50}
            maxLength={50}
            onChange={(e) => setName(e.target.value)}
          />
          <TextInput
            label="Email"
            type="email"
            name="email"
            required
            max={50}
            maxLength={50}
            autoComplete="email"
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextInput
            label="Empresa"
            name="company"
            max={50}
            maxLength={50}
            autoComplete="organization"
          />
          <TextInput
            label="Telefone"
            type="tel"
            name="phone"
            autoComplete="tel"
            max={50}
            maxLength={50}
          />
          <TextInput
            required
            label="Mensagem"
            name="message"
            max={500}
            maxLength={500}
            onChange={(e) => setMessage(e.target.value)}
          />
        </div>
        <SubmitButton
          externalDisabled={!linkify.test(email) || !name || !message}
        />
      </form>
    </FadeIn>
  )
}
