export function RadioInput({
  label,
  ...props
}: React.ComponentPropsWithoutRef<'input'> & { label: string }) {
  return (
    <label className="flex gap-x-3">
      <input
        type="radio"
        {...props}
        className="h-6 w-6 flex-none appearance-none rounded-full border border-[#C09042]/20 outline-none checked:border-[0.5rem] checked:border-[#C09042] focus-visible:ring-1 focus-visible:ring-[#C09042] focus-visible:ring-offset-2"
      />
      <span className="text-base/6 text-[#C09042]">{label}</span>
    </label>
  )
}
