import {
  BrevoListNames,
  BrevoLists,
  BrevoTemplate,
  BrevoTemplateNames,
  Mailable,
} from '@/constants/constants.exports'

type TransactionalEmailResponse = {
  messageId: string
}

type IClient = {
  email: string
  name?: string
  company?: string
}

export class BrevoService {
  private baseUrl: string

  constructor() {
    this.baseUrl = process.env.BREVO_API_ENDPOINT!
  }

  sendTransactional = async <T>({
    params,
    to,
    templateId,
  }: Mailable<T>): Promise<TransactionalEmailResponse | null> => {
    try {
      const body = JSON.stringify({
        to: [
          {
            email: to,
          },
        ],
        templateId,
        params,
        headers: {
          charset: 'iso-8859-1',
        },
      })
      const [transactionalResponse, contactResponse] = await Promise.all([
        this.request('smtp/email', 'POST', body),
        this.upsertContact({
          email: to,
          //@ts-ignore
          name: params?.name,
          //@ts-ignore
          company: params?.company,
        }),
      ])

      const response = await this.request('smtp/email', 'POST', body)

      const result = await response.json()
      return result
    } catch (error) {
      console.error(error)
      return null
    }
  }

  /**
   *
   */
  upsertContact = async (client: IClient) => {
    try {
      const body = JSON.stringify({
        updateEnabled: true,
        email: client.email,
        listIds: [BrevoLists.Onboarding, BrevoLists.Newsletter],
        attributes: {
          name: client.name,
        },
      })

      const response = await this.request('contacts', 'POST', body)
      console.log(await response.json())
    } catch (error) {
      console.error(error)
    }
  }

  getListId = (listName: BrevoListNames) => {
    switch (listName) {
      case 'Onboarding':
        return BrevoLists[listName]

      case 'Newsletter':
        return BrevoLists[listName]

      default:
        return null
    }
  }

  getTemplateId = (templateName: BrevoTemplateNames) => {
    switch (templateName) {
      case 'WelcomeToNewsletter':
        return BrevoTemplate[templateName]
      case 'ContactMeReply':
        return BrevoTemplate[templateName]

      default:
        return null
    }
  }
  /**
   *
   */
  addToList = async (
    email: string,
    isNewUser: boolean,
    listName: BrevoListNames,
  ) => {
    try {
      let body = ''
      const listId = this.getListId(listName)
      if (isNewUser) {
        await this.upsertContact({
          email,
        })
      } else {
        body = JSON.stringify({
          email: email,
        })
        console.log('the passed body ', body)
        return await this.request(
          `contacts/lists/${listId}/contacts/add`,
          'POST',
          body,
        )
      }
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  /**
   *
   */
  private request = async (
    resource: string,
    method: 'POST',
    body?: string,
  ): Promise<Response> => {
    const headers = {
      Accept: 'application/json',
      'Content-type': 'application/json',
      'api-key': process.env.BREVO_API_KEY!,
    }

    const url = `${this.baseUrl}/${resource}`

    const response = await fetch(url, {
      method,
      headers,
      body,
    })

    return response
  }
}

const brevoService = new BrevoService()
export { brevoService }
